import React, { StrictMode, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './components/App';
import ThemeContext from './components/ThemeContext';
import './stylesheets/main.css';


const queryClient = new QueryClient();

const Component = () => {
    const theme = useState("white");

    return (
        <StrictMode>
            <QueryClientProvider client={queryClient}>
                <ThemeContext.Provider value={theme}>
                    <Router>
                        <App />
                    </Router>
                </ThemeContext.Provider>
            </QueryClientProvider>
        </StrictMode>
    );
};


createRoot(document.getElementById('root')).render(<Component />)