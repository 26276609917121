// SearchParams.js
import { useState, useEffect, useContext, useCallback } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import ThemeContext from './ThemeContext';
import Results from './Results';
import { Client } from '@petfinder/petfinder-js';

const pf = new Client({ apiKey: process.env.API_KEY, secret: process.env.SECRET });

const usePetSearch = (formData) => {
    return useQuery(['pets', formData], async () => {
        const type = {};
        if (formData.animal) type.type = formData.animal;
        if (formData.location) type.location = formData.location;
        if (formData.breed) type.breed = formData.breed;

        const res = await pf.animal.search(type);

        if (!res.data) {
            throw new Error(res.statusText);
        }

        return res.data.animals.filter(animal => animal.photos.length);
    }, {
        keepPreviousData: true,
        select: (data) => data.sort((a, b) => a.distance - b.distance)
    });
};

const SearchParams = () => {
    const [formData, setFormData] = useState({
        location: "",
        animal: "",
        breed: ""
    });
    const [animalTypes, setAnimalTypes] = useState([]);
    const [breedsList, setBreedsList] = useState([]);
    const [theme, setTheme] = useContext(ThemeContext);

    const { data: pets = [], isLoading, isError } = usePetSearch(formData);

    useEffect(() => {
        const fetchAnimalTypes = async () => {
            try {
                const res = await pf.animalData.types();
                const types = res.data.types.map(type => type.name);
                setAnimalTypes(types);
            } catch (err) {
                console.error(err.message);
            }
        };

        fetchAnimalTypes();
    }, []);

    useEffect(() => {
        const fetchBreedsList = async () => {
            if (formData.animal) {
                try {
                    const res = await pf.animalData.breeds(formData.animal);
                    const breeds = res.data.breeds.map(breed => breed.name);
                    setBreedsList(breeds);
                } catch (err) {
                    console.error(err.message);
                }
            } else {
                setBreedsList([]);
            }
        };

        fetchBreedsList();
    }, [formData.animal]);

    const debouncedSetFormData = useCallback(
        debounce((newFormData) => setFormData(newFormData), 300),
        []
    );

    const handleInputChange = e => {
        const { name, value } = e.target;
        debouncedSetFormData(prevState => ({
            ...prevState,
            [name]: value === "All" ? "" : value
        }));
    };

    const handleSubmit = e => {
        e.preventDefault();
        const [city, state] = e.target.location.value.split(/,\s*/);

        if (!city || !state) {
            alert("Please enter a city and a state separated by a comma");
            e.target.location.value = "";
            e.target.location.focus();
            return;
        }

        setFormData(prevState => ({
            ...prevState,
            location: `${city}, ${state.toUpperCase()}`
        }));
    };

    return (
        <>
            <label htmlFor="animal">
                Animal
                <select
                    id="animal"
                    name="animal"
                    value={formData.animal}
                    onChange={handleInputChange}
                >
                    <option value="All">All</option>
                    {animalTypes.map(animal => (
                        <option key={animal} value={animal}>{animal}</option>
                    ))}
                </select>
            </label>

            <label htmlFor="breed">
                Breed
                <select
                    disabled={!breedsList.length}
                    id="breed"
                    name="breed"
                    value={formData.breed}
                    onChange={handleInputChange}
                >
                    <option value="All">All</option>
                    {breedsList.map(breed => (
                        <option key={breed} value={breed}>{breed}</option>
                    ))}
                </select>
            </label>

            <form onSubmit={handleSubmit}>
                <label htmlFor="location">
                    Location
                    <input
                        type="text"
                        id="location"
                        name="location"
                        placeholder="City, State Abbreviation"
                        autoFocus
                    />
                </label>

                <button style={{ backgroundColor: theme }}>Submit</button>
            </form>

            {isLoading && <p>Loading...</p>}
            {isError && <p>Error fetching pets.</p>}
            {!isLoading && !isError && <Results pets={pets} />}
        </>
    );
};

export default SearchParams;
